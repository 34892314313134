@include get-font('HelveticaNeue', '../../fonts/helvetica-bold-700', 700, bold, woff2);
@include get-font('HelveticaNeue', '../../fonts/helvetica-regular-400', 400, normal, woff2);
@include get-font('HelveticaNeue', '../../fonts/helvetica-light-300', 300, normal, woff2);

@include get-font('IBMPlexMono', '../../fonts/IBMPlexMono-Bold', 700, bold, woff2);
@include get-font('IBMPlexMono', '../../fonts/IBMPlexMono-SemiBold', 600, semi-bold, woff2);
@include get-font('IBMPlexMono', '../../fonts/IBMPlexMono-Medium', 500, bold, woff2);
@include get-font('IBMPlexMono', '../../fonts/IBMPlexMono-Regular', 400, bold, woff2);

@include get-font('DMSans', '../../fonts/DMSans-Bold', 700, bold, woff2);
@include get-font('DMSans', '../../fonts/DMSans-Medium', 500, bold, woff2);
@include get-font('DMSans', '../../fonts/DMSans-Regular', 400, bold, woff2);
