:root {
  --color-white: #fff;
  --color-white-rgb: 255, 255, 255;

  --color-black: #000;
  --color-black-rgb: 0, 0, 0;

  --color-gray-25: #fcfcf9;
  --color-black-rgb-25: 252, 252, 249;

  --color-gray-100: #f0f3f6;
  --color-black-rgb-100: 240, 243, 246;

  --color-gray-200: #eaecf0;
  --color-black-rgb-200: 234, 236, 240;

  --color-gray-300: #f3f3f3;
  --color-black-rgb-300: 227, 230, 232;

  --color-gray-500: #667085;
  --color-black-rgb-500: 102, 112, 133;

  --color-gray-700: #273444;
  --color-black-rgb-700: 39, 52, 68;

  --color-gray-800: #192129;
  --color-black-rgb-800: 25, 33, 41;

  --color-gray-900: #101828;
  --color-black-rgb-900: 16, 24, 40;

  --color-green: #58cc02;
  --color-green-rbg: 88, 204, 2;

  --color-yellow: #ffc738;
  --color-yellow-rbg: 255, 199, 56;

  --color-orange: #f79009;
  --color-orange-rbg: 247, 144, 9;

  --color-rose: #e31b54;
  --color-rose-rbg: 227, 27, 84;

  --color-indigo: #444ce7;
  --color-indigo-rbg: 68, 76, 231;

  --color-purple: #6938ef;
  --color-purple-rbg: 105, 56, 239;

  // font-size display
  --fs-d-xl: 60px;
  --fs-d-lg: 48px;
  --fs-d-md: 36px;
  --fs-d-xs: 24px;

  // font-size text
  --fs-t-xl: 20px;
  --fs-t-lg: 18px;
  --fs-t-md: 16px;
  --fs-t-sm: 14px;
  --fs-t-xs: 12px;

  // font-weight bold
  --fw-b: 700;
  // font-weight semi-bold
  --fw-sb: 600;
  // font-weight medium
  --fw-m: 500;
  // font-weight regular
  --fw-r: 400;

  // --font-base: 'IBMPlexMono', sans-serif;
  // --font-secondary: 'DMSans', sans-serif;
  body[data-lang='font_ge'] {
    --font-base: 'HelveticaNeue';
    --font-secondary: 'HelveticaNeue';
  }
  --font-base: 'IBMPlexMono', sans-serif;
  --font-secondary: 'DMSans', sans-serif;
}
