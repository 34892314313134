body {
  color: var(--color-gray-700);
  font-size: var(--fs-t-lg);
  font-family: var(--font-secondary);
  font-weight: var(--fw-r);
  line-height: 1.3;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;

  ::selection {
    background: var(--color-yellow);
  }
}

.get_in_touch_swiper {
  .swiper-slide {
    opacity: 0 !important;
    text-align: center;

    span {
      &:nth-child(1) {
        display: block;
        margin-bottom: 34px;

        svg {
          transition: 0.3s linear;
          transform: scale(0.5);
        }
      }

      &:nth-child(2) {
        font-family: var(--font-base);
        font-style: normal;
        font-weight: var(--fw-sb);
        font-size: var(--fs-d-xs);
        line-height: 1.3;
        color: var(--color-black);

        @media (max-width: 1280px) {
          svg {
            width: 70%;
          }
        }

        @media (max-width: 1024px) {
          font-size: var(--fs-t-xl);
        }

        @media (max-width: 768px) {
          font-size: var(--fs-t-md);
          padding: 0 10px;
        }
      }
    }
  }

  .swiper-slide-active {
    opacity: 1 !important;

    span {
      &:nth-child(1) {
        svg {
          padding: 20px;
          transform: scale(1);
        }
      }
    }
  }
}

.AppPartnerSlider_swiper {
  max-width: 1920px;
  margin: 0 auto;
}

.Portfolio_Inner__Swiper {
  max-width: 720px;

  .swiper-slide {
    margin-bottom: 48px;
  }

  .PortfolioInnerNavigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 420px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }

    .PortfolioInnerNavigationNext,
    .PortfolioInnerNavigationPrev {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;

      font-family: var(--font-base);
      font-style: normal;
      font-weight: var(--fw-m);
      font-size: var(--fs-t-lg);
      line-height: 1.3;
      color: var(--color-black);

      cursor: pointer;

      span {
        transition: 0.3s ease;
      }
    }

    .PortfolioInnerNavigationNext {
      &:hover {
        span {
          transform: translateX(4px);
        }
      }
    }

    .PortfolioInnerNavigationPrev {
      &:hover {
        span {
          transform: translateX(-4px);
        }
      }
    }
  }
}

.AppGetInTouchNavigation {
  position: absolute;
  bottom: 4px;

  display: flex;
  justify-content: space-between;
  width: 100%;

  .AppGetInTouchNavigationNext,
  .AppGetInTouchNavigationPrev {
    cursor: pointer;
    z-index: 1000;
  }
}

.appteam_slider {
  .swiper-slide {
    height: auto;
  }

  @media (max-width: 1280px) {
    .swiper-slide {
      display: flex;
      justify-content: center;
    }
  }
}

.AppTeam_Slider_Navigarion {
  display: flex;

  .AppTeam_Slider_Next {
    button {
      padding: 0;
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        svg {
          width: 18px;
          height: 18px;

          path {
            stroke: var(--color-black);
          }
        }
      }
    }
  }

  .AppTeam_Slider_Prev {
    button {
      padding: 0;
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        svg {
          width: 13px;
          height: 13px;

          path {
            stroke: var(--color-black);
          }
        }
      }
    }
  }
}

.AppTeam_Slider_Navigarion {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1480px;

  @media (max-width: 1280px) {
    top: unset;
    bottom: -36px;
    max-width: 200px;
  }

  @media (max-width: 576px) {
    bottom: 0px;
    z-index: 1;
  }
}

.AppBlogInner_MainTitle {
  font-family: var(--font-base);
  font-weight: var(--fw-sb);
  font-size: 30px;
  color: var(--color-black);
  margin-bottom: var(--fs-d-xs);
}

.AppBlogInner_MainDesc {
  font-size: var(--fs-t-lg);
  color: var(--color-gray-700);
  margin-bottom: 48px;
}

.AppBlogInner_Image_Box {
  margin-bottom: 40px;

  @media (max-width: 768px) {
    margin: 0 20px 20px 20px;
  }

  figure {
    max-width: 720px;
    margin: 0 auto;

    figcaption {
      padding-top: 16px;
      font-size: var(--fs-t-sm);
      line-height: var(--fs-t-lg);
      color: var(--color-gray-700);
    }
  }
}

.AppBlogInner_Quote {
  font-family: var(--font-base);
  font-weight: var(--fw-m);
  font-size: 30px;
  color: var(--color-black);
  margin-bottom: 40px;

  @media (max-width: 768px) {
    font-size: 24px;
    margin-bottom: 30px;
  }

  span {
    display: block;
    margin-top: 32px;
    font-size: var(--fs-t-md);
    text-align: right;
    color: var(--color-gray-700);
  }
}

.AppBlogInner_Title {
  font-family: var(--font-base);
  font-weight: var(--fw-sb);
  font-size: var(--fs-d-xs);
  color: var(--color-black);
  margin-bottom: var(--fs-t-md);
  padding-top: 0 !important;
}

.AppBlogInner_Desc {
  font-size: var(--fs-t-lg);
  color: var(--color-gray-700);
  margin-bottom: 48px;
}

.custom_Text {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 20px;
    padding: 0 15px;
  }

  .link {
    padding-top: 30px;
  }
}

.custom_Text_min {
  max-width: 720px;
  padding: 0 15px 48px;
  margin: auto;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 20px;
  }

  .link {
    padding-top: 30px;
  }
}

.AppRichTextLarge {
  padding: 32px 0;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 64px;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    padding: 12px 0;
    gap: 0px;
  }

  &__Left {
    h3 {
      font-family: var(--font-base);
      font-style: normal;
      font-weight: var(--fw-sb);
      font-size: var(--fs-d-md);
      line-height: 1.3;
      color: var(--color-black);
      // margin-bottom: 20px;
    }

    &__Desc {
      font-family: var(--font-secondary);
      font-style: normal;
      font-weight: var(--fw-r);
      font-size: var(--fs-t-xl);
      line-height: 1.3;
      color: var(--color-gray-700);
    }
  }

  &__Right {
    h4 {
      font-family: var(--font-base);
      font-style: normal;
      font-weight: var(--fw-b);
      font-size: var(--fs-d-xs);
      line-height: 1.3;
      color: var(--color-black);

      margin-bottom: 24px;
    }

    p {
      font-family: var(--font-secondary);
      font-style: normal;
      font-weight: var(--fw-r);
      font-size: var(--fs-t-lg);
      line-height: 1.3;
      color: var(--color-gray-700);

      margin-bottom: 48px;
    }
  }
}

.AppPortfolioInner__TextBanner {
  background-color: var(--color-gray-25);

  .AppPortfolioInner__TextBanner__Content {
    font-family: var(--font-base);
    font-style: normal;
    font-weight: var(--fw-m);
    font-size: var(--fs-t-lg);
    line-height: 1.2;
    text-align: center;
    color: var(--color-black);

    p {
      font-family: var(--font-base);
      font-style: normal;
      font-weight: var(--fw-m);
      font-size: var(--fs-t-lg);
      line-height: 1.2;
      text-align: center;
      color: var(--color-black);
    }
  }
}

.AppPortfolioInner__TextBanner__Content {
  padding: 48px 0;
  max-width: 720px;
  margin: 0 auto 20px auto;

  font-family: var(--font-secondary);
  font-style: normal;
  font-weight: var(--fw-r);
  font-size: var(--fs-t-lg);
  line-height: 1.3;
  color: var(--color-gray-700);

  @media (max-width: 1440px) {
    padding: 40px 0;
  }

  @media (max-width: 1024px) {
    padding: 30px 20px;
  }

  p {
    margin-bottom: 20px;
  }
}

.AppPortfolioInner__fullBannerImage {
  width: 100%;
  text-align: center;

  img {
    border: 2px solid var(--color-black);
  }
}

.container {
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1280px;
}

ul {
  li {
    &.active {
      a {
        color: var(--color-black) !important;
      }
    }
  }
}

.AppPortfolioInner__fullBannerSmallImage {
  display: flex;
  align-items: center;
  justify-content: center;
}